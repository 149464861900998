// const baseUrl = "http://dev.saturncube.com:7799/api";
const baseUrl = "https://api.gfc.golf/api";

export const getCountryList = `${baseUrl}/countries`;
export const getStateList = `${baseUrl}/states`;
export const getCityList = `${baseUrl}/cities`;

export const getOtpVerification = `${baseUrl}/golf-admin/otp-check`;
export const getAdminregister = `${baseUrl}/golf-admin/register`;
export const getOtpOnEmail = `${baseUrl}/golf-admin/send-mail`
export const AdminLogin = `${baseUrl}/golf-admin/login`
export const getSpecialList = `${baseUrl}/golf-admin/list-special`
export const CreateSpecial = `${baseUrl}/golf-admin/create-special`
export const ArchivedSpacial = `${baseUrl}/golf-admin/update-status`
export const getspecialDetails = `${baseUrl}/golf-admin/get-special`
export const Editspecial = `${baseUrl}/golf-admin/update-special`
export const GetArchivedSpacialList = `${baseUrl}/golf-admin/list-archived-special`
export const EditAdminProfile = `${baseUrl}/golf-admin/update-profile`
export const getAdminDetails = `${baseUrl}/golf-admin/auth`
export const AdminChangePassword = `${baseUrl}/golf-admin/change-password`
export const getPayoutList = `${baseUrl}/golf-admin/list-special-golf-payment`
export const getNewPayoutList = `${baseUrl}/golf-admin/month_wise_commission`
export const getForgotPassword = `${baseUrl}/golf-admin/forgot-password`
export const getpayoutStats = `${baseUrl}/golf-admin/commission`
export const getDashboardStats = `${baseUrl}/golf-admin/commission`
export const getPayoutHistory = `${baseUrl}/golf-admin/paid_history`
export const getBookingList = `${baseUrl}/golf-admin/booking_player_list`
export const getBookingDetails = `${baseUrl}/golf-admin/booking_details`
export const getPriceBreakDown = `${baseUrl}/golf-admin/price_breakdown`

