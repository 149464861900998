import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardGFCSpecials  = Loadable(lazy(() => import('views/golfCourse/Specials')));
const DashboardGFCBooking  = Loadable(lazy(() => import('views/golfCourse/Booking')));
const DashboardGFCPayouts  = Loadable(lazy(() => import('views/golfCourse/Payouts')));
const AppUserAccountProfile1 = Loadable(lazy(() => import('views/application/users/account-profile/Profile1')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/user/account-profile',
            element: <AppUserAccountProfile1 />
        },

        {
            path: '/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/specials',
            element: <DashboardGFCSpecials />
        },
        {
            path: '/dashboard/booking',
            element: <DashboardGFCBooking />
        },
        {
            path: '/dashboard/payouts',
            element: <DashboardGFCPayouts />
        }
    ]
};

export default MainRoutes;
