
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';



// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics
};



const dashboard = {
    
    id: 'dashboard',
    // title: (
    //     <div className='cst_Admin_Info'>
    //         <h1>Golf Course1</h1>
    //         <p>goldcourse1@gmail.com</p>
    //     </div>
    // ),
    type: 'group',
    children: [
        {
            id: 'Dashboard',
            title: <FormattedMessage id="Dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        // {
        //     id: 'analytics',
        //     title: <FormattedMessage id="analytics" />,
        //     type: 'item',
        //     url: '/dashboard/analytics',
        //     icon: icons.IconDeviceAnalytics,
        //     breadcrumbs: false
        // }
    ]
};

export default dashboard;
