import axios from "axios";

export const getToken = () => {
    const localStorageToken = localStorage.getItem('token');
    const sessionStorageToken = sessionStorage.getItem('token');

    const token = sessionStorageToken || localStorageToken || '';
    return axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

export const removeOuterSpaces = (sentence) => {
    const trimmedSentence = sentence.trim(); // Remove spaces from start and end
    const cleanedSentence = trimmedSentence.split(/\s+/).join(' '); // Remove extra inner spaces
    return cleanedSentence;
}

// export const mergeDates = (date) => {
//     const day = date.getDate().toString().padStart(2, '0');
//     const month = (date.getMonth() + 1).toString().padStart(2, '0');
//     const year = date.getFullYear();
//     return `${day}-${month}-${year}`;
// };

export const formatDateRange = (startISOString, endISOString) => {
    const startDate = new Date(startISOString);
    const endDate = new Date(endISOString);
  
    const formattedStartDate = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
    const formattedEndDate = `${endDate.getDate()}-${endDate.getMonth() + 1}-${endDate.getFullYear()}`;
  
    return `${formattedStartDate} - ${formattedEndDate}`;
  }