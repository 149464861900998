import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const isLoggedIn = useAuth();
    // const [isLoggedIn, setIsLoggedIn] = useState(false)
    const navigate = useNavigate();
    // useEffect(() => {
    //     if (localStorage.getItem("token") !== null && sessionStorage.getItem("token") !== null) {
    //         // setIsLoggedIn(true);
    //         navigate('/', { replace: true });
    //     }
    // }, [navigate])


    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/', { replace: true });
        }
    }, [isLoggedIn, navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
