import { useContext } from 'react';

// auth provider
import AuthContext from 'contexts/FirebaseContext';
import { getToken } from 'Helper/Token';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import AuthContext from 'contexts/Auth0Context';
// import AuthContext from 'contexts/JWTContext';
// import AuthContext from 'contexts/AWSCognitoContext';

// ==============================|| AUTH HOOKS ||============================== //



const useAuth = () => {
    const navigate = useNavigate();
    const isTokenAvailable = localStorage.getItem("token") !== null || sessionStorage.getItem("token") !== null;

    const getAdminDetails = () => {
        getToken();
        axios.get(getAdminDetails)
            .then((res) => {
                if (res.response && res.response.status === 401) {
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate("/login");
                }
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate("/login");
                }
            });
    }

    return isTokenAvailable;
};

export default useAuth;
