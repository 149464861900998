// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUserCheck, IconBasket, IconMessages, IconLayoutKanban, IconMail, IconCalendar, IconNfc, IconCalendarEvent, IconGolf } from '@tabler/icons';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import PaidIcon from '@mui/icons-material/Paid';
import StarsIcon from '@mui/icons-material/Stars';
// constant
const icons = {
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconCalendarEvent,
    IconGolf,
    BookmarkAddIcon,
    PaidIcon,
    StarsIcon
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const application = {
    // id: 'application',
    // title: <FormattedMessage id="application" />,
    type: 'group',
    children: [
        {
            id: 'Specials',
            title: <FormattedMessage id="Specials" />,
            type: 'item',
            icon: icons.StarsIcon,
            url: '/dashboard/specials'
            // children: [
            //     {
            //         id: 'posts',
            //         title: <FormattedMessage id="GFC Tournament " />,
            //         type: 'item',
            //         url: '/user/social-profile/posts'
            //     },
            //     {
            //         id: 'posts',
            //         title: <FormattedMessage id="GFC Open Play " />,
            //         type: 'item',
            //         url: '/user/social-profile/posts'
            //     },
            //     {
            //         id: 'posts',
            //         title: <FormattedMessage id="GFC Monthly " />,
            //         type: 'item',
            //         url: '/user/social-profile/posts'
            //     },
            //     // {
            //     //     id: 'account-profile',
            //     //     title: <FormattedMessage id="account-profile" />,
            //     //     type: 'collapse',
            //     //     children: [
            //     //         {
            //     //             id: 'profile1',
            //     //             title: (
            //     //                 <>
            //     //                     <FormattedMessage id="profile" /> 01
            //     //                 </>
            //     //             ),
            //     //             type: 'item',
            //     //             url: '/user/account-profile'
            //     //         },
            //     //         {
            //     //             id: 'profile2',
            //     //             title: (
            //     //                 <>
            //     //                     <FormattedMessage id="profile" /> 02
            //     //                 </>
            //     //             ),
            //     //             type: 'item',
            //     //             url: '/user/account-profile/profile2'
            //     //         },
            //     //         {
            //     //             id: 'profile3',
            //     //             title: (
            //     //                 <>
            //     //                     <FormattedMessage id="profile" /> 03
            //     //                 </>
            //     //             ),
            //     //             type: 'item',
            //     //             url: '/user/account-profile/profile3'
            //     //         }
            //     //     ]
            //     // },
            //     // {
            //     //     id: 'user-card',
            //     //     title: <FormattedMessage id="cards" />,
            //     //     type: 'collapse',
            //     //     children: [
            //     //         {
            //     //             id: 'card1',
            //     //             title: (
            //     //                 <>
            //     //                     <FormattedMessage id="style" /> 01
            //     //                 </>
            //     //             ),
            //     //             type: 'item',
            //     //             url: '/user/card/card1'
            //     //         },
            //     //         {
            //     //             id: 'card2',
            //     //             title: (
            //     //                 <>
            //     //                     <FormattedMessage id="style" /> 02
            //     //                 </>
            //     //             ),
            //     //             type: 'item',
            //     //             url: '/user/card/card2'
            //     //         },
            //     //         {
            //     //             id: 'card3',
            //     //             title: (
            //     //                 <>
            //     //                     <FormattedMessage id="style" /> 03
            //     //                 </>
            //     //             ),
            //     //             type: 'item',
            //     //             url: '/user/card/card3'
            //     //         }
            //     //     ]
            //     // },
            //     // {
            //     //     id: 'user-list',
            //     //     title: <FormattedMessage id="list" />,
            //     //     type: 'collapse',
            //     //     children: [
            //     //         {
            //     //             id: 'list1',
            //     //             title: (
            //     //                 <>
            //     //                     <FormattedMessage id="style" /> 01
            //     //                 </>
            //     //             ),
            //     //             type: 'item',
            //     //             url: '/user/list/list1'
            //     //         },
            //     //         {
            //     //             id: 'list2',
            //     //             title: (
            //     //                 <>
            //     //                     <FormattedMessage id="style" /> 02
            //     //                 </>
            //     //             ),
            //     //             type: 'item',
            //     //             url: '/user/list/list2'
            //     //         }
            //     //     ]
            //     // }
            // ]
        },
        {
            id: 'Bookings',
            title: <FormattedMessage id="Bookings" />,
            type: 'item',
            icon: icons.BookmarkAddIcon,
            url: '/dashboard/booking'
            // children: [
            //     {
            //         id: 'customer-list',
            //         title: <FormattedMessage id="customer-list" />,
            //         type: 'item',
            //         url: '/customer/customer-list',
            //         breadcrumbs: false
            //     },
            //     {
            //         id: 'order-list',
            //         title: <FormattedMessage id="order-list" />,
            //         type: 'item',
            //         url: '/customer/order-list',
            //         breadcrumbs: false
            //     },
            //     {
            //         id: 'create-invoice',
            //         title: <FormattedMessage id="create-invoice" />,
            //         type: 'item',
            //         url: '/customer/create-invoice',
            //         breadcrumbs: false
            //     },
            //     {
            //         id: 'order-details',
            //         title: <FormattedMessage id="order-details" />,
            //         type: 'item',
            //         url: '/customer/order-details'
            //     },
            //     {
            //         id: 'product',
            //         title: <FormattedMessage id="product" />,
            //         type: 'item',
            //         url: '/customer/product',
            //         breadcrumbs: false
            //     },
            //     {
            //         id: 'product-review',
            //         title: <FormattedMessage id="product-review" />,
            //         type: 'item',
            //         url: '/customer/product-review',
            //         breadcrumbs: false
            //     }
            // ]
        },
        {
            id: 'Payouts',
            title: <FormattedMessage id="Payouts" />,
            type: 'item',
            icon: icons.PaidIcon,
            url: '/dashboard/payouts'
        },
        // {
        //     id: 'CMS',
        //     title: <FormattedMessage id="CMS" />,
        //     type: 'item',
        //     icon: icons.IconMessages,
        //     url: '/app/chat'
        // },
        // {
        //     id: 'kanban',
        //     title: 'Kanban',
        //     type: 'item',
        //     icon: icons.IconLayoutKanban,
        //     url: '/app/kanban/board'
        // },
        // {
        //     id: 'mail',
        //     title: <FormattedMessage id="mail" />,
        //     type: 'item',
        //     icon: icons.IconMail,
        //     url: '/app/mail'
        // },
        // {
        //     id: 'calendar',
        //     title: <FormattedMessage id="calendar" />,
        //     type: 'item',
        //     url: '/app/calendar',
        //     icon: icons.IconCalendar,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'contact',
        //     title: <FormattedMessage id="contact" />,
        //     type: 'collapse',
        //     icon: icons.IconNfc,
        //     children: [
        //         {
        //             id: 'c-card',
        //             title: <FormattedMessage id="cards" />,
        //             type: 'item',
        //             url: '/app/contact/c-card',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'c-list',
        //             title: <FormattedMessage id="list" />,
        //             type: 'item',
        //             url: '/app/contact/c-list',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        // {
        //     id: 'e-commerce',
        //     title: <FormattedMessage id="e-commerce" />,
        //     type: 'collapse',
        //     icon: icons.IconBasket,
        //     children: [
        //         {
        //             id: 'products',
        //             title: <FormattedMessage id="products" />,
        //             type: 'item',
        //             url: '/e-commerce/products'
        //         },
        //         {
        //             id: 'product-details',
        //             title: <FormattedMessage id="product-details" />,
        //             type: 'item',
        //             url: '/e-commerce/product-details/default',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'product-list',
        //             title: <FormattedMessage id="product-list" />,
        //             type: 'item',
        //             url: '/e-commerce/product-list',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'checkout',
        //             title: <FormattedMessage id="checkout" />,
        //             type: 'item',
        //             url: '/e-commerce/checkout'
        //         }
        //     ]
        // }
    ]
};

export default application;
